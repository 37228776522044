<template>
  <b-form @submit.prevent="submitUpdate">
    <b-tabs>
      <b-tab title="Concurso">
        <div class="card">
          <div class="card-body">
            <draggable
              v-model="contest"
              class="list-group list-group-flush cursor-move my-0"
              tag="ul"
              @change="onChangeSort"
            >
              <b-list-group-item
                v-for="(item, index) in contest"
                :key="index"
                tag="li"
                class="pl-0 pr-0 py-0 mb-50"
                style="border: 0px;"
              >
                <app-collapse accordion type="margin">
                  <app-collapse-item :title="`${index + 1} - ${item.title ? item.title : 'Item'} `">
                    <div class="form-row">
                      <div class="col-md-11">
                        <div class="form-group">
                          <input type="text" v-model="item.title" placeholder="Título" class="form-control">
                        </div>
                      </div>

                      <div class="col-md-1">
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          @click="removeItem(index)"
                          block
                        >
                          <i class="bi bi-x"></i>
                        </b-button>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group mb-0">
                          <textarea placeholder="Texto" v-model="item.description" class="form-control"></textarea>
                        </div>
                      </div>
                    </div>
                  </app-collapse-item>
                </app-collapse>
              </b-list-group-item>
            </draggable>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="repeateAgain('contest')"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>Adicionar</span>
            </b-button>
          </div>
        </div>
      </b-tab>
      <b-tab title="Produto">
        <div class="card">
          <div class="card-body">
            <draggable
              v-model="product"
              class="list-group list-group-flush cursor-move my-0"
              tag="ul"
              @change="onChangeSort"
            >
              <b-list-group-item
                v-for="(item, index) in product"
                :key="index"
                tag="li"
                class="pl-0 pr-0 py-0 mb-50"
                style="border: 0px;"
              >
                <app-collapse accordion type="margin">
                  <app-collapse-item :title="`${index + 1} - ${item.title ? item.title : 'Item'} `">
                    <div class="form-row">
                      <div class="col-md-11">
                        <div class="form-group">
                          <input type="text" v-model="item.title" placeholder="Título" class="form-control">
                        </div>
                      </div>

                      <div class="col-md-1">
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          @click="removeItem(index)"
                          block
                        >
                          <i class="bi bi-x"></i>
                        </b-button>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group mb-0">
                          <textarea placeholder="Texto" v-model="item.description" class="form-control"></textarea>
                        </div>
                      </div>
                    </div>
                  </app-collapse-item>
                </app-collapse>
              </b-list-group-item>
            </draggable>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="repeateAgain('product')"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>Adicionar</span>
            </b-button>
          </div>
        </div>
      </b-tab>
    </b-tabs>

    <div class="row d-flex justify-content-end">
      <div class="col-md-3">
        <ButtonsActionsFooter
          variant="success"
          :submited="submited"
          text="Salvar"
          subtext="Aguarde..."
        />
      </div>
    </div>

    <p v-if="updating" class="mb-0te text-success">
      <b-spinner label="Loading..." small class="mr-1" />
      Atualizando...
    </p>
  </b-form>
</template>

<script>
import {
  BCard,
  BCol,
  BSpinner,
  BListGroupItem,
  BCardBody,
  BListGroup,
  BFormInput,
  BInputGroup,
  BButton,
  BForm,
  BRow,
  BTabs,
  BTab,
} from "bootstrap-vue";

import draggable from "vuedraggable";
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import { required } from "vuelidate/lib/validators";

export default {
  directives: {
    Ripple,
  },
  data() {
    return {
      nextProductId: 0,
      nextContestId: 0,
      product: [],
      contest: [],
      updating: false,
      submited: false,
    };
  },
  components: {
    AppCollapse,
    BSpinner,
    AppCollapseItem,
    BCard,
    BListGroupItem,
    BCardBody,
    BListGroup,
    draggable,
    BFormInput,
    BInputGroup,
    BButton,
    BRow,
    BForm,
    BTabs,
    BCol,
    BTab,
    ButtonsActionsFooter,
  },
  created() {
    this.getData()
  },
  methods: {
    submitUpdate() {      
      this.$v.$touch();
      this.submited = true;

      if (this.contest.length && this.product.length) {
        this.$store
          .dispatch("Faq/create", { contest: this.contest, product: this.product })
          .then(() => {
            this.notify(
              "Operação realizada com sucesso!",
              "UserCheckIcon",
              "success",
              "",
            );
          })
          .catch((err) => {
            this.submited = false;
          })
          .finally(() => {
            this.submited = false;
          });
      } else {
        this.submited = false;
        this.notifyDefault("error", 'Verifique os campos obrigatórios');
      }
    },
    async getData() {
      this.$store.dispatch("Faq/all").then((res) => {
        this.product = res.product
        this.contest = res.contest
      });
    },
    onChangeSort(event) {
      if (event.moved) {
        this.updating = true;
        /*
        this.$store
          .dispatch(`Sort/${route}`, { id: this.category.code, items: itemsArray })
          .then(() => {
            this.notifyDefault("success");
            this.getData();
          })
          .catch(() => {
            this.notifyDefault("error", 'Oops, algo não saiu como esperado.');
          })
          .finally(() => {
            this.updating = false;
          });
        */
      }
    },
    repeateAgain(type) {
      if (type === 'product') {
        this.product.push({
          id: this.nextProductId + this.product.length,
          title: '',
          description: '',
          category: 'product',
        });
      } else {
        this.contest.push({
          id: this.nextProductId + this.contest.length,
          title: '',
          description: '',
          category: 'contest',
        });
      }
    },
    removeItem(index) {
      this.product.splice(index, 1);
    },
  },
  validations: {
    contest: {
      title: {
        required,
      },
      description: {
        required,
      },
    },
    product: {
      title: {
        required,
      },
      description: {
        required,
      },
    },
  }
};
</script>
